import React, { useState } from 'react';
import {
  ChakraProvider,
  Box,
  Heading,
  VStack,
  Input,
  Button,
  Select,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';

const App = () => {
  const [file, setFile] = useState(null);
  const [inputFormat, setInputFormat] = useState('');
  const [outputFormat, setOutputFormat] = useState('');
  const [downloadLink, setDownloadLink] = useState('');
  const toast = useToast();

  const supportedFormats = ['jpeg', 'png', 'webp', 'tiff', 'gif'];

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log('File selected:', file);
    if (!file) {
      toast({
        title: 'Error',
        description: 'No file selected.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setFile(file);
    const mimeType = file.type;
    console.log('MIME type:', mimeType);
    const format = mimeType.split('/')[1];
    console.log('Detected format:', format);
    setInputFormat(format);
  };

  const handleFormatChange = (event) => {
    const selectedFormat = event.target.value;
    console.log('Output format selected:', selectedFormat);
    setOutputFormat(selectedFormat);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Form submitted');
    if (!file || !outputFormat) {
      toast({
        title: 'Error',
        description: 'Please select a file and output format.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append('image', file);
    console.log('Form data prepared:', formData);

    try {
      const response = await axios.post(
        `https://api.smallconvert.com/${inputFormat}/to/${outputFormat}`,
        formData,
        {
          responseType: 'blob',
        }
      );
      console.log('API response:', response);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      console.log('Download link generated:', url);
      setDownloadLink(url);
      toast({
        title: 'Success',
        description: 'File converted successfully!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error converting the image:', error);
      toast({
        title: 'Error',
        description: 'Failed to convert file.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <ChakraProvider>
      <Box
        minHeight="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bg="gray.100"
        px={4}
      >
        <Box
          bg="white"
          p={8}
          rounded="md"
          shadow="md"
          width="100%"
          maxWidth="500px"
        >
          <Heading as="h1" size="lg" mb={6} textAlign="center">
            Convert Your Images
          </Heading>
          <VStack as="form" spacing={4} onSubmit={handleSubmit}>
            <Input type="file" accept="image/*" onChange={handleFileChange} />
            <Select placeholder="Select output format" onChange={handleFormatChange}>
              {supportedFormats.map((format) => (
                <option key={format} value={format}>
                  {format.toUpperCase()}
                </option>
              ))}
            </Select>
            <Button colorScheme="blue" type="submit" width="full">
              Convert
            </Button>
          </VStack>
          {downloadLink && (
            <Box mt={4} textAlign="center">
              <a href={downloadLink} download={`converted.${outputFormat}`}>
                <Button colorScheme="green">Download Converted File</Button>
              </a>
            </Box>
          )}
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default App;
